
.landing {
    position: relative;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;

    & > div:first-of-type {
        display: flex;
        flex-direction: column;
        max-width: 35%;
        margin-left: 10vw;
        padding-bottom: 10vh;

        h1 {
        margin-bottom: 15px;
        }

        button {
        margin-top: 50px;
        padding: 10px 80px;
        }
    }

    .graphs {
        max-width: 50%;
        position: relative;

        & > div {
        height: 30vh;
        position: absolute;
        top: 30%;
        left: -4%;
        padding: 10px 30px;
        background-color: rgb(34, 31, 44);
        filter: drop-shadow(0px 8px 18px rgba(255, 255, 255, 0.5));
        border-radius: 32px;

        & > video {
            height: 100%;
        }
        }

        img {
        height: 75vh;
        }
    }
}

.analytics {
    position: relative;
    & > h1 {
        margin-bottom: 50px;
    }

    & > span {
        max-width: 52vw;
    }

    & > div {
        padding-top: 25px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        span {
        color: var(--text-primary);
        margin-bottom: 25px;
        }

        & > img {
        height: 50vh;

            &:last-of-type {
                padding-right: 15vw;
            }

            &:first-of-type {
                padding-left: 15vw;
            }
        }
    }
}

.features {
    & > h1 {
        margin-bottom: 96px;
    }

    .chart-video {
        height: 40vh;
        border-radius: 32px;
        background-color: rgb(34, 31, 44);

        & > video {
            border-radius: 32px;
            height: 100%;
        }
    }

    & > div {
        display: flex;
        flex-direction: column;
        padding: 0 10vw;
        gap: 120px;

        .feature {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 100px;

        h2 {
            margin-bottom: 30px;
        }

        & > img {
            height: 45vh;
        }
        }
    }

    & button {
        margin-top: 50px;
        padding: 10px 80px;
    }
    }

    .pass {
    & > h1 {
        margin-bottom: 32px;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 80px;
        margin-top: 32px;
        padding: 0 10vw;

        h2 {
        margin-bottom: 30px;
        }

        & > img {
        height: 45vh;
        }
    }
}
  
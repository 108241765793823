.contact-form {
    & > form {
        min-width: 30vw;

        & > * {
            width: 100%;
        }

        & > .names {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 12px;

            & > * {
                width: 100%;
            }
        }

        & > button {
            margin-top: 24px;
            transition: background-color 300ms ease-in-out;

            &.disabled {
                background-color: #ccc;
                pointer-events: none;
                cursor: not-allowed;
            }
        }
    }
}
:root {
  --body-bg: #000000;
  --section-bg: #12131A;
  --text-primary: #FFFFFF;
  --text-secondary: #5E6E78;
  --btn-bg-primary: #EF2A82;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
}

@font-face {
  font-family: "Montserrat";
  src: url("./resources/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./resources/fonts/Montserrat-Regular.woff") format("woff"),
    url("./resources/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./resources/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./resources/fonts/Roboto-Regular.woff") format("woff"),
    url("./resources/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  font-family: 'Roboto';
}

a {
  text-decoration: none;
}

button {
  border-radius: 68px;
  padding: 15px 30px;
  font-size: 16px;
  display: inline-block;
  height: fit-content;
  width: fit-content;
  border: none;
  cursor: pointer;
}

button.outlined {
  background-color: transparent;
  border: 1px solid white;
  color: var(--text-primary);
}

button.primary {
  background-color: var(--btn-bg-primary);
  color: var(--text-primary);
}

button.strict-edges {
  border-radius: 16px;
}

h1 {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  color: var(--text-primary);
}

h2 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: var(--text-primary);
}

h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: var(--text-primary);
}

h5 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  color: var(--text-primary);
}

.description {
  font-size: 18px;
  color: var(--text-secondary);
}

section {
  padding: 68px 0;
  position: relative;
  background-color: var(--section-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-bg {
  background-color: var(--section-bg);
}

section:last-of-type {
  padding-bottom: 0;
}

.top-cross-section {
  position: absolute;
  z-index: -1;
  bottom: -75vh;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: skewY(-10deg);
  background-color: var(--section-bg);
  box-shadow: inset 0 10px 8px -5px rgba(255, 255, 255, 0.5);
}

.bottom-cross-section {
  position: absolute;
  z-index: -1;
  top: -75vh;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: skewY(-10deg);
  background-color: var(--section-bg);
  box-shadow: inset 0 -10px 8px -5px rgba(255, 255, 255, 0.5);
}

.mt-24 {
  margin-top: 24px;
}
.jobs {
    h1 {
        margin-bottom: 8px;
    }

    .list {
        display: flex;
        padding: 0 7.5vw;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
        margin-top: 48px;

        & > div {
            display: flex;
            flex-direction: column;
            padding: 24px;
            background-color: #262626;
            flex-basis: calc(33.333333% - 35px);
            height: 410px;
            border-radius: 30px;

            button {
                margin-top: auto;
                width: 100%;
                cursor: pointer;
            }
        }
    }

    .location {
        font-style: italic;
        color: #EF2A82;
        margin-bottom: 12px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--text-primary);
        margin-bottom: 8px;
    }

    & .description {
        color: #C4C4C4;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}

.ReactModal__Content {
    display: flex;
    flex-direction: column;
    padding: 24px !important;
    border-radius: 30px !important;
    & > div {
        overflow: auto;
    }

    .location {
        font-style: italic;
        color: #EF2A82;
        margin-bottom: 12px;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        color: var(--text-primary);
        margin-bottom: 8px;
    }

    .description {
        color: #C4C4C4;
    }

    button {
        margin-top: auto;
        width: 100%;
    }
}

#application-form {
    padding: 0 7.5vw;
    display: flex;
    flex-direction: column;

    h1 {
        margin-bottom: 8px;
    }

    .form {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        width: 100%;

        & > div {
            display: flex;
            flex-direction: column;
            width: 40%;
            gap: 14px;
        }
    }

    & > .input {
        margin-top: 24px;
    }

    button {
        align-self: flex-start;
        margin-top: 50px;
        min-width: 418px;
    }
}

.select {
    position: relative;
    margin-bottom: 45px;

    & > span {
        color: var(--text-primary);
        display: block;
        font-style: italic;
        font-size: 22px;
    }

    .placeholder {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding: 21px 0;
        cursor: pointer;

        & > span {
            font-size: 22px;
            color: #999999;
        }
    }

    .menu {
        display: none;
        position: absolute;
        background-color: #35363B;
        margin-top: 12px;
        border-radius: 16px;
        width: 100%;

        &.open {
            display: initial;
        }

        & > div {
            color: var(--text-primary);
            padding: 16px;
            cursor: pointer;

            &:not(:last-of-type) {
                border-bottom: 1px solid #707070;
            }
        }
    }
}

.input {
    & > span {
        color: var(--text-primary);
        display: block;
        font-style: italic;
        font-size: 22px;
        margin-bottom: 14px;
    }

    & > div {
        padding: 12px 16px;
        background-color: rgba(158, 158, 158, 0.25);
        border-radius: 4px;

        input, textarea {
            background-color: transparent;
            border: none;
            color: var(--text-primary);
            width: 100%;
            
            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #707070;
                opacity: 1;
            }
        }

        textarea {
            height: 316px;
            resize: none;
        }
    }
}
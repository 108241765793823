.faq {
    & > .questions {
        margin-top: 20px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 100%;
        max-width: 920px;

        & > .accordion {
            font-size: 18px;
            font-weight: 700;
            color: var(--text-primary);
            padding: 22px 0;
            border-bottom: 1px solid white;
            cursor: pointer;

            &:last-of-type {
                border: none;
            }

            & > .question {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > span:last-of-type {
                    font-size: 28px;
                    transform: rotate(0deg);
                    transition: transform 300ms ease-in-out;
                }
            }

            & > .answer {
                font-size: 16px;
                font-weight: 400;
                max-height: 0;
                overflow: hidden;
                transition: max-height 300ms ease-in-out;

                & > a {
                    color: var(--text-primary);
                    text-decoration: underline;
                }
            }

            &.expanded {
                & > .question {
                    & > span:last-of-type {
                        transform: rotate(45deg);
                    }
                }

                & > .answer {
                    max-height: 200px;
                    overflow: hidden;
                }
            }
        }
    }
}
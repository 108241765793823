.homepage {
  position: relative;

  &.noscroll {
    overflow: hidden;
    height: 100vh;
  }
}

.bg-shape {
  position: absolute;
  z-index: -1;
  fill: #5E6E78;

  &.small {
    top: 0;
    left: 0;
    width: 35vw;
  }

  &.big {
    top: 0;
    right: 0;
    width: 70vw;
  }
}

.banner {
  padding: 4px;
  text-align: center;
  width: 100%;
  background-color: #EF2A82;

  & a {
    color: var(--text-primary);
    font-size: 20px;
  }
}

header {
  padding: 27px 10vw;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    img {
      height: 54px;
    }
  }

  nav {
    display: flex;
    align-items: flex-end;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: flex;
      gap: 36px;
    }

    li {
      a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 16px;

        &.active {
          border-bottom: 1px solid white;
        }
      }
    }
  }

  button {
    max-width: 210px;
    padding: 10px 50px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-controls {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

footer {
  position: relative;
  padding: 41vh 10vw 30px 10vw;
  text-align: center;

  & .email-input {
    display: flex;
    margin-top: 24px;
    padding-left: 24px;

    input {
      background-color: transparent;
      border: none;
      color: var(--text-primary);
      width: 100%;
      
      &:focus {
          outline: none;
      }

      &::placeholder {
          color: #707070;
          opacity: 1;
      }
    }

    background: rgba(158, 158, 158, 0.25);
    border-radius: 30px;
  }
  
  & > div:last-of-type {
    padding: 30px 50px 80px 30px;
    border-top: 1px solid #2A407C;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    nav {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
  
      li {
        a {
          display: block;
          color: white;
          text-align: center;
          text-decoration: none;
          font-size: 16px;
        }
      }
    }
  }
}

.wl-overlay{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 550;
}

.wl-popup{
  & a {
    color: white;
    text-decoration: underline;
  }

  .btn {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: var(--btn-bg-primary);
  }
}

.mobile-banner {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background: #12131A;
    border-radius: 25px;
    padding: 40px 88px 90px 88px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;

    & > img {
      height: 10vh;
      margin-bottom: 2vh;
    }

    & > h3 {
      font-size: 2rem;
    }

    & > .description {
      margin: 2vh 0;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      padding: 0 3vw;
    }
  }
}
.pricing-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 7.5vw;  
    margin-top: 50px;
    width: 100%;
    position: relative;

    & > .controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
    }

    .pricing-mode {
        display: flex;
        align-items: center;
        padding: 0 16px;

        background: #35363B;
        border-radius: 68px;

        & > span {
            padding: 0 8px;
            color: var(--text-primary);
        }

        &:last-of-type {
            padding: 0 12px;

            & > span {
                font-size: 18px;
            }
            & > img {
                padding-left: 12px;
            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked {
                & + .slider {
                    background-color: var(--btn-bg-primary);
                }

                & + .slider::before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }
    }

    .feature-table {
        width: 100%;
        color: var(--text-primary);
        position: relative;
        z-index: 0;

        thead tr td {
            font-size: 30px;
            font-weight: 600;
            padding: 16px 0;
            text-align: center;
            cursor: pointer;
            position: relative;
            z-index: 2;

            &:nth-of-type(3) {
                border-radius: 40px 40px 0 0;
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            min-height: 100%;
            min-width: 20vw;
            background-color: rgba(239, 42, 130, .6);
            border-radius: 40px;
            z-index: 1;

            transition: right 400ms ease-in-out;
        }

        tbody {
            & tr {
                &:nth-last-of-type(even) {
                    background-color: #35363B;
                }

                &:first-of-type td:first-of-type {
                    border-top-left-radius: 40px;
                }

                &:first-of-type td:last-of-type {
                    border-top-right-radius: 40px;
                }

                &:nth-of-type(11) td:first-of-type {
                    border-bottom-left-radius: 40px;
                }
                
                &:nth-of-type(11) td:last-of-type {
                    border-bottom-right-radius: 40px;
                }

                &:last-of-type td {
                    font-size: 40px;
                    border-radius: 0 0 40px 40px;
                }

                & td {
                    padding: 12px 0;
                    font-size: 20px;
                    font-weight: 700;
                    position: relative;
                    z-index: 2;

                    &:not(:first-of-type) {
                        text-align: center;
                        width: 20vw;
                    }

                    &:first-of-type {
                        padding-left: 80px;
                    }
                }
            }
        }
    }
}

.pass-section {
    & > h1 {
        margin-top: 40px;
    }

    & .pass-types {
        display: flex;
        width: 100%;
        padding: 0 7.5vw;
        justify-content: space-between;
        gap: 20px;
        margin-top: 40px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 18px 50px -15px #FFFFFF;
            flex-basis: calc(33.333333% - 45px);
            border-radius: 30px;
            padding: 60px;

            & > .description {
                padding-bottom: 12px;

                &:first-of-type {
                    width: 50%;
                    text-align: center;
                    border-bottom: 1px solid #000;
                    margin-top: 20px;
                }
            }

            & > img {
                padding: 40px;
            }


            & a  {
                min-width: 80%;

                & > button {
                    width: 100%;
                }
            }
        }
    }
}
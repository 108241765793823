.features-heading {
    padding: 50px 0 104px 0;
    background-color: var(--section-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
}

.feature img {
    border-radius: 40px;
}